/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Modificar ordenadores con fines estéticos o para aumentar su rendimiento, es decir el modding y el overclock, son practicas que siempre me han parecido fascinantes. Últimamente todo esto está de capa caída, pasó la moda que dicen, pero aun así a quien realmente le gusta el tema continua haciendo mod increíbles. Algunas creaciones pueden llegar a ser muy interesantes. A continuación hago una lista de páginas muy interesantes, relacionadas con estos temas."), "\n", React.createElement(_components.p, null, "Modding:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.hardcore-modding.com/",
    title: "Hardcore Modding"
  }, "http://www.hardcore-modding.com/")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.hard-h2o.com/",
    title: "Hard h2o"
  }, "http://www.hard-h2o.com/")), "\n", React.createElement(_components.p, null, "Dos viejas conocidas con sus respectivos foros sobre modding y temas relacionados, sobretodo me gusta la primera por su comunidad tan DIY."), "\n", React.createElement(_components.p, null, "Overclock:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.overclockear.com/",
    title: "Overclockear"
  }, "http://www.overclockear.com/")), "\n", React.createElement(_components.p, null, "En el overclock sobretodo me gustan los sistemas de refrigeración, como intentan enfriar a máximo el procesador para poder sacarle unos Hertzs más."), "\n", React.createElement(_components.p, null, "Aunque la mayoría de Modders utilizan el SO Windows también es posible utilizar GNU/Linux para estos ordenadores, ya que tenemos a nuestra disposición ", React.createElement(_components.a, {
    href: "http://www.overclock.net/linux-unix/248684-linux-benchmarks.html",
    title: "Linux Benchmarks"
  }, "Benchmarks"), ", fundamentales para el overclock y además la gran capacidad de personalizar GNU/Linux hace que podamos ser mas respetuosos con el medio ambiente haciendo que ", React.createElement(_components.a, {
    href: "http://www.lesswatts.org/index.php",
    title: "LessWatts"
  }, "nuestras computadoras consuman menos"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
